.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 1vw;
    padding-left: 3vw;
    padding-bottom: 9vh;
    border-radius: 1vw;
    width: 70vw; /* Relative to viewport width */
    height: 70vh; /* Relative to viewport height */
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
  }
  
  .modal-header {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start;
    font-size: 1vw;
  }

  .modal-tags {
    display: flex; /* Layout the tags inline */
    flex-wrap: wrap; /* Allow them to wrap */
    max-width: 30vw;
  }

  .modal-tag {
    display: inline-block;
    background: #C3BCF4;
    color: #5444D3;
    padding: 0.4vw 0.6vw;
    border-radius: 5px;
    margin: 0 0.5vw 0.5vw 0;
    font-size: 0.9vw;
  }

  .modal-section {
    text-align: left;
    font-size: 1.2vw;
    margin-bottom: 0px;
    color: #333;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
  }

  .modal-links {
    display: flex;
    flex-direction: column;
    width: 100%; /* Full width to match the carousel above */
    text-align: left;
    font-size: 1vw;
    margin-top: 1vh;
  }

  .modal-text {
    text-align: left;
    font-size: 1vw;
    margin-bottom: 0px;
    margin-top: 1vh;
    color: #333;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
  }

  .modal-text ul {
    list-style-type: disc; /* Choose the style of bullet you prefer */
    padding-left: 0; /* Remove padding if you want bullets inline with the text */
    margin-top: 0.7vh;
    margin-bottom: 1vh;
  }
  
  .modal-text li {
    margin-bottom: 0.7vh; /* Space between list items */
    text-indent: -1em; /* This creates the indent for the text */
    padding-left: 1em; /* This offsets the text to align with the bullet */
  }
  
  .modal-text li ul {
    list-style-type: circle; /* Different style for nested lists */
    padding-left: 0; /* Indent nested lists */
  }

  
  
  .modal-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
    margin-top: 1vh;
    align-items: start;
  }
  
  .modal-column-details {
    text-align: left;
    height: 55vh; /* Fixed height for scrollable area */
    overflow-y: auto; /* Enables scrolling */
    flex: 1; /* Takes up half the width */
    padding-right: 1vw;
  }
  
  .modal-column-carousel {
    flex: 1; /* Takes up half the width */
    display: flex;
    flex-direction: column; /* Stack image carousel and links */
    align-items: center; /* Center items horizontally */
    justify-content: center;
    padding-right: 1vw;
    padding-left: 1vw;
    width: 90%;
    height: 55vh;
  }

  .carousel .slide {
    background: none;
    }
    
    .carousel-caption {
        background-color: rgba(199, 199, 199, 0.7); /* Light background with lowered opacity */
        color: #333;
        padding: 7px;
        text-align: center;
        position: absolute;
        bottom: 5%; /* Position above the navigation dots */
        left: 50%;
        transform: translateX(-50%); /* Center the caption */
        max-width: 90%; /* Don't let the caption span the full width */
        border-radius: 5px; /* Optional: add rounded corners */
        box-sizing: border-box;
        text-shadow: 0 0 3px white; /* Subtle text-shadow to increase readability */
        font-size: 0.7vw;
    }
    
    .carousel .control-dots {
        position: absolute;
        bottom: 1%; /* Position dots at the bottom */
        width: 100%;
        text-align: center;
    }

    .carousel .control-dots .dot {
        background: #acacac; /* Neutral color for dots */
        opacity: 0.3; /* Slightly transparent */
        box-shadow: none; /* Remove any box-shadow */
        border: none; /* Remove borders */
    }
    
    .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
        background: #333; /* A different color for the active dot */
        opacity: 0.3;
    }
  
  .modal-close-button {
    position: absolute;
    top: 3vh;
    right: 3vw;
    border: none;
    background: transparent;
    cursor: pointer;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
    font-size: 1.2vw;
    color: #333;
  }
  
  /* Styles for list of technical details */
  .modal-column-details ul {
    list-style: inside;
    padding-left: 0;
  }
  
  /* Styles for individual list items */
  .modal-column-details li {
    margin-bottom: 5px;
  }

  /* Extra small screens (Small phones, less than 480px) */
  @media (max-width: 600px) {
    .modal-body {
        grid-template-columns: 1fr; /* Stack columns vertically for extra small screens */
    }

    .modal-header {
      font-size: 3vw;
      text-align: left;
    }
  
    .modal-tags {
      max-width: 70vw;
    }
  
    .modal-tag {
      padding: 0.6vw 0.8vw;
      border-radius: 0.6vw;
      margin: 0 0.5vw 0.5vw 0;
      font-size: 2.3vw;
    }

    .modal-section {
      font-size: 2.8vw;
    }

    .modal-text {
      font-size: 2.3vw;
    }

    .modal-links {
      font-size: 2.3vw;
    }

    .modal-column-details {
      /*disable scrolling for small screens*/
      overflow-y: hidden;
      height: auto;
    }

    .modal-backdrop {
      overflow-y: hidden; /* Ensure the backdrop doesn't scroll */
    }

    .modal-column-carousel {
      height: auto; /* Adjust height as per your design preference */
      width: 100%; /* Use the full width of the modal */
      margin-top: 2vh;
      align-items: center;
    }

    .carousel img {
        max-width: 60%; /* Ensure images don't overflow their container */
        object-fit: contain; /* Fit the image within the dimensions without stretching it */
    }

    .carousel-caption {
      font-size: 1.7vw;
  }

    .modal-content {
      padding: 4vw; /* Adjust the padding for smaller screens */
      overflow-y: auto; /* Add scroll to the entire modal */
      width: 80vw; /* Relative to viewport width */
      height: 70vh; 
    }
  
    .modal-text, .modal-column-details, .modal-column-carousel {
      padding: 0; /* Remove extra padding inside the modal sections */
    }
  }
  
  
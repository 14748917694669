.page-title {
  text-align: left;
  font-size: 2rem;
  margin-bottom: 0px;
  color: #333;
  font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
}

.projects-titles {
  max-width: 100;
  margin: auto;
  padding: 20px 40px;
  font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
}

.section-title {
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: 0px;
  color: #707070;
  font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Ensures equal spacing */
  gap: 30px; /* Space between the cards */
  margin-top: 20px;
  }
  
  .project-card {
    width: 200px; /* Adjust width as needed */
    height: auto; /* Adjust height as needed */
    flex-basis: calc(33.333% - 30px); /* Three per row, minus the gap */
    border-radius: 10px; /* Rounded corners for the card */
    transition: transform 0.2s ease-in-out;
    border: 2px solid transparent;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
  }
  
  .project-card:hover {
    transform: translateY(-5px); /* Optional: Adds a small hover effect */
  }
  
  .project-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px 10px 10px 10px; /* Rounded corners for the top of the image */
    border: 1px solid #c4c4c4;
    margin-bottom: 0;
  }
  
  .project-info {
    padding: 10px 5px;
    background-color: #fff;
    position: relative;
  }
  
  .project-title {
    margin-top: 5px; /* Reduces space above the title */
    margin-bottom: 0; 
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left;
  }
  
  .project-description {
    font-size: 0.9rem;
    margin-top: 5px;
    color: #666;
    text-align: left;
  }
  
  .project-arrow {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 1.5rem;
    color: #333;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .projects-container {
      flex-direction: column;
      align-items: center;
    }
  
    .project-card {
      margin-bottom: 10px;
    }
  }
  
.timeline {
    max-width: 100;
    margin: auto;
    padding: 20px 40px;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
  }
  
  .timeline-entry {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 30px;
  }

  .timeline-header {
    text-align: left;
    font-size: 2rem;
    margin-bottom: 40px;
    color: #333;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
  }
  
  .timeline-year {
    color: #333;
    margin-right: 20px;
    font-weight: bold;
    flex-shrink: 0;
  }
  
  .timeline-logo {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    border-radius: 20%;
    object-fit: cover;
    margin-right: 15px;
  }
  
  .timeline-text {
    /* border-left: 2px solid #ccc; */
    padding-left: 15px;
    text-align: left;
    font-size: 1.1rem;
  }
  
  .timeline-title {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .timeline-company {
    margin: 0;
    font-weight: bold;
    color: #333;
  }
  
  .timeline-description {
    margin: 5px 0;
    color: #333;
    background-color: #fff;
  }
  
  @media (max-width: 768px) {
    .timeline-entry {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .timeline-year,
    .timeline-logo {
      margin-bottom: 10px;
    }
  
    .timeline-text {
      border-left: none;
      padding-left: 0;
    }
  }
  
body, html {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Aligns content to the top */
    height: 100%;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
  }
  
  .intro {
    display: flex;
    align-items: center; /* Aligns children of .intro vertically */
    justify-content: flex-start; /* Aligns children of .intro horizontally */
    width: 100%;
    max-width: 80%; /* Adjust max-width as needed */
    padding: 6%;
    box-sizing: border-box;
    font-family: 'San Francisco', 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    background-color: #fff;
    margin-left: 10%;
  }
  
  .intro-image-wrapper {
    flex-shrink: 0; /* Prevents the image from shrinking */
    display: flex;
    flex-direction: column;
    align-items: center; /* Aligns the image and icons vertically */
    margin-right: 5%;
  }
  
  .intro-image {
    width: 200px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5%;
  }
  
  .intro-text {
    flex-grow: 1; /* Allows the text to take up the remaining space */
    text-align: left; /* Align text to the left */
    margin-left: 5%;
  }
  
  .intro-name {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .intro-title {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  
.intro-description {
    font-size: 1.2rem;
    max-width: 600px; /* Prevents the text from becoming too wide */
    line-height: 1.4; /* Increase line spacing */
}
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .intro {
      flex-direction: column;
      text-align: center;
    }
  
    .intro-image {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .intro-text {
      flex-grow: 0;
    }
  }
  
  .social-icons {
    display: flex;
    justify-content: center; /* Center the icons horizontally */
    margin-top: 5%; /* Space from the image */
  }
  
  .social-icon {
    font-size: 1.5rem; /* Adjust icon size as needed */
    margin: 0 10%; /* Spacing between icons */
    color: #333; /* Icon color, adjust as needed */
    text-decoration: none; /* Remove underline from icons */
    display: flex;
    align-items: center; /* Align icons vertically */
  }